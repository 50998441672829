<template>
  <div
    style="text-align: center;
    margin-top: 200px;
}"
  >
    <img
      src="https://lgbfss-www.oss-cn-beijing.aliyuncs.com/www/404.png"
      alt=""
    />
    <p style="font-size:14px">
      页面没有找到
      <el-button type="text" v-prev path="/login">返回首页</el-button>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
